import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import svgs from "./svgs";
import "external-svg-loader";

const SVG = ({ name, color, src, alt, id, className, dataCy, ariaHidden }) => {
  useEffect(() => {
    // for accessibility purposes
    if (typeof window !== "undefined") {
      const svgWrapperId = `${id}-wrapper`;

      const svgWrapperElement = document.getElementById(svgWrapperId);
      if (svgWrapperElement && id) {
        const svgElement = svgWrapperElement.children[0];

        // only proceed if the title element doesn't exist yet
        if (
          (svgElement.firstChild &&
            svgElement.firstChild.id !== `${id}-title`) ||
          !svgElement.firstChild
        ) {
          const titleElement = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "title"
          );
          titleElement.textContent = alt;
          titleElement.id = `${id}-title`;

          // after page has loaded, append needed accessibility fields
          svgElement.setAttribute("aria-labelledby", `${id}-title`);
          svgElement.setAttribute("id", `${id}`);
          svgElement.setAttribute("role", "img");
          svgElement.insertBefore(titleElement, svgElement.firstChild);
        }
      }
    }
  }, [name, src]);

  return (
    <S.SVG_div
      color={color}
      className={className}
      data-cy={dataCy}
      aria-hidden={ariaHidden}
      {...(id ? { id: `${id}-wrapper` } : {})}
      data-test={name}
    >
      {src ? <svg data-src={src} stroke="currentColor"></svg> : svgs[name]}
    </S.SVG_div>
  );
};

SVG.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
  ariaHidden: PropTypes.bool,
};

SVG.defaultProps = {
  dataCy: "svg",
  ariaHidden: false,
};

export default SVG;
