export default {
  billboard1: "h1",
  billboard2: "h2",
  button: "p",
  buttonLite: "p",
  buttonSmall: "p",
  caption1: "p",
  caption2: "p",
  caption1Bold: "strong",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  label: "p",
  link: "p",
  p1: "p",
  p2: "p",
  italic: "em",
};
