import styled from "styled-components";

// MagicText DOM element type may change depending on type prop passed
export const MagicText_element = styled.p`
  ${(props) => props.theme.typography[props.type]};
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.grayscale.charcoal};
  // p tags have margin top and bottom by default :'(
  margin: 0px;
`;
