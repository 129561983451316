import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { theme } from "@constants/theme";
import typographyMap from "./typographyMap";

const MagicText = ({ className, children, type, element, color, dataCy }) => {
  return (
    // MagicText DOM element type may change depending on type prop passed
    <S.MagicText_element
      className={className}
      type={type}
      color={color}
      theme={theme}
      as={element ? element : typographyMap[type]}
      data-cy={dataCy}
    >
      {children}
    </S.MagicText_element>
  );
};

MagicText.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    "billboard1",
    "billboard2",
    "button",
    "buttonLite",
    "buttonSmall",
    "caption1",
    "caption2",
    "caption1Bold",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "label",
    "link",
    "p1",
    "p2",
    "italic",
  ]).isRequired,
  element: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "strong",
    "em",
  ]),
  dataCy: PropTypes.string,
};

MagicText.defaultProps = { dataCy: "magic-text" };

export default MagicText;
